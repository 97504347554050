.layout-topbar {
  position: fixed;
  height: 4rem;
  z-index: 999;
  left: 0;
  top: 0;
  //   border-radius: 0 0 20px 20px;
  width: 100%;
  padding: 0 2rem;
  background-color: var(--primary-color);
  color: var(--surface-50);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08);

  div {
    display: flex;
    align-items: center;
  }

  .layout-topbar-button {
    width: 30px;
    cursor: pointer;
  }
  .layout-topbar-logo {
    max-height: 90%;
    margin-left: 30px;
    cursor: pointer;
  }
}
